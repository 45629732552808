import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Card, Select, Result, notification, Modal } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const initialValues = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  password: undefined,
  phone: '',
  avatar: undefined,
  role: [],
};

const UserForm = ({
  data,
  updateHandler,
  createHandler,
  result,
  setResult,
  SERVER_URL,
  token,
  showModal,
  setShowModal,
  profile,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  initialValues.legalType = currentuser.data && currentuser.data.role.includes('superAdmin') ? 'PRIVATE' : 'CLIENT';
  let user = data ? { ...data } : initialValues;
  const isNew = data ? false : true;
  const [deletePop, setDeletePop] = useState();
  const [branches, setBranches] = useState([]);
  const [companyBranch, setCompanyBranch] = useState([]);
  const [loggedUser, fetchLoggedUser] = useAxios('', [], currentuser.data.token, 'get');
  const [companies, fetchCompanies] = useAxios('', [], currentuser.data.token, 'get');
  const [selectedRole, setSelectedRole] = useState(data && data.role && data.role[0]);

  const getBranches = async () => {
    try {
      const call = await Axios.post(
        `${SERVER_URL}/company/branches`,
        { companies: companyBranch.length ? companyBranch : data.companies },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      setBranches(call.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchLoggedUser(`${SERVER_URL}/users/${currentuser.data.id}`, []);
    fetchCompanies(`${SERVER_URL}/companies`, []);
  }, [data, SERVER_URL, currentuser.data.id, fetchCompanies, fetchLoggedUser]);

  useEffect(() => {
    getBranches();
  }, [companyBranch]);

  useEffect(() => {
    if (data && data.avatar) setImage(data.avatar.url);
  }, [data]);

  if (data && data.companyAdminInfo) {
    user.address = data.companyAdminInfo.address;
    user.city = data.companyAdminInfo.city;
    user.zip = data.companyAdminInfo.zip;
    user.country = data.companyAdminInfo.country;
    user.companyName = data.companyAdminInfo.companyName;
    user.companyNumber = data.companyAdminInfo.companyNumber;
    user.taxId = data.companyAdminInfo.taxId;
  }

  if (data) {
    user.companies = data.companies.map((company) => company._id);
    user.branch = data?.branch?.map((b) => b._id);
  }

  const onFinish = (values) => {
    if (
      user.role.length !== 0 &&
      user.role[0] !== values.role &&
      values.role === 'company' &&
      values.companies.length > 1
    ) {
      values.companies = values.companies[0];
    }
    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
    setShowModal(!showModal);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Greška:', errorInfo);
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Stavka je obrisana.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        history.push('/admin/users');
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim Vas da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const fillCompanyData = (companyId) => {
    if (companies.data && companies.data.items && companies.data.items.length > 0) {
      const selectedCompany = companies.data.items.filter((item) => item._id === companyId);
      if (selectedCompany.length !== 0) {
        form.setFieldsValue({
          address: selectedCompany[0].address,
          city: selectedCompany[0].city,
          zip: selectedCompany[0].zip,
          country: selectedCompany[0].country,
          companyName: selectedCompany[0].companyName,
          companyNumber: selectedCompany[0].companyNumber,
          taxId: selectedCompany[0].taxId,
        });
      }
    }
  };

  return (
    <>
      <div className='dashboard'>
        <div className='card-wrapper'>
          {/* eslint-disable-next-line no-useless-concat */}
          <Card title={`${isNew ? 'Novi korisnik' : 'Izmena korisnika'}`} bordered={false}>
            <CloseOutlined className='x-btn' onClick={() => setShowModal(false)} />
            {profile && (
              <div className='delete-user' onClick={() => setDeletePop(!deletePop)}>
                Deaktivacija korisnika <DeleteOutlined />
              </div>
            )}
            {!result && (
              <Form
                {...layout}
                name='basic'
                initialValues={user}
                onFinish={(values) => onFinish(values, isNew)}
                onFinishFailed={onFinishFailed}
                layout='horizontal'
                form={form}
              >
                <Form.Item /*label='Avatar'*/ className='upload-wrapper right' name='avatar'>
                  <UploadBox
                    editHandler={avatarHandler}
                    deleteHandler={deleteAvatarHandler}
                    image={image}
                    index={0}
                    name='avatar'
                  />
                </Form.Item>

                <Form.Item
                  /*label='Email'*/
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas da unesete email!',
                    },
                  ]}
                >
                  <Input placeholder='Email' />
                </Form.Item>

                <Form.Item
                  /*label='First name'*/
                  name='firstName'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas da unesete ime!',
                    },
                  ]}
                >
                  <Input placeholder='Ime' />
                </Form.Item>

                <Form.Item
                  /*label='Last name'*/
                  name='lastName'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas da unesete prezime!',
                    },
                  ]}
                >
                  <Input placeholder='Prezime' />
                </Form.Item>

                <Form.Item /*label='Phone'*/ name='phone'>
                  <Input placeholder='Telefon' />
                </Form.Item>

                {isNew && (
                  <Form.Item
                    /*label='Password'*/
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: 'Molim Vas da unesete password!',
                      },
                    ]}
                  >
                    <Input.Password placeholder='Password' />
                  </Form.Item>
                )}

                <Form.Item
                  /*label='Role'*/
                  name='role'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas da odaberete role!',
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownAlign={{ offset: [0, -140] }}
                    placeholder='Role'
                    onChange={setSelectedRole}
                  >
                    <Select.Option value='superAdmin'>Super admin</Select.Option>
                    <Select.Option value='companyAdmin'>Company admin</Select.Option>
                    <Select.Option value='company'>Company</Select.Option>
                    <Select.Option value='production'>Production</Select.Option>
                  </Select>
                </Form.Item>

                {selectedRole !== 'superAdmin' && selectedRole !== 'production' && (
                  <Form.Item
                    name='companies'
                    rules={[
                      {
                        required: true,
                        message: 'Molim Vas da odaberete kompaniju!',
                      },
                    ]}
                  >
                    <Select
                      placeholder='Kompanija'
                      showSearch
                      onChange={(e) => {
                        fillCompanyData(e);
                        setCompanyBranch(e);
                      }}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option.key?.indexOf(input) >= 0
                      }
                    >
                      {companies.data &&
                        companies.data.items &&
                        companies.data.items.length > 0 &&
                        companies.data.items.map((item) => (
                          <Select.Option key={item.companyCode} value={item._id}>
                            {item.companyName}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}

                {selectedRole === 'company' && (
                  <Form.Item
                    name='branch'
                    rules={[
                      {
                        required: true,
                        message: 'Please select branch!',
                      },
                    ]}
                  >
                    <Select
                      placeholder='Branch'
                      mode='multiple'
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {branches?.map((branch) => (
                        <Select.Option key={branch._id} value={branch._id}>
                          {branch.branchName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {selectedRole && selectedRole === 'companyAdmin' && (
                  <>
                    <Form.Item
                      name='address'
                      rules={[
                        {
                          required: true,
                          message: 'Molim Vas da unesete adresu!',
                        },
                      ]}
                    >
                      <Input placeholder='Adresa' />
                    </Form.Item>

                    <Form.Item
                      name='city'
                      rules={[
                        {
                          required: true,
                          message: 'Molim Vas da unesete grad!',
                        },
                      ]}
                    >
                      <Input placeholder='Grad' />
                    </Form.Item>

                    <Form.Item
                      name='zip'
                      rules={[
                        {
                          required: true,
                          message: 'Molim Vas da unesete poštanski kod!',
                        },
                      ]}
                    >
                      <Input placeholder='Poštanski kod' />
                    </Form.Item>

                    <Form.Item name='country'>
                      <Input placeholder='Zemlja' />
                    </Form.Item>

                    <Form.Item
                      name='companyName'
                      rules={[
                        {
                          required: true,
                          message: 'Molim Vas da unesete naziv kompanije!',
                        },
                      ]}
                    >
                      <Input placeholder='Naziv kompanije' />
                    </Form.Item>

                    <Form.Item
                      name='companyNumber'
                      rules={[
                        {
                          required: true,
                          message: 'Molim Vas da unesete matični broj!',
                        },
                      ]}
                    >
                      <Input placeholder='Matični broj kompanije' />
                    </Form.Item>

                    <Form.Item
                      name='taxId'
                      rules={[
                        {
                          required: true,
                          message: 'Molim Vas da unesete PIB!',
                        },
                      ]}
                    >
                      <Input placeholder='PIB' />
                    </Form.Item>
                  </>
                )}

                {!isNew && (
                  <Form.Item
                    /*label='Status'*/
                    name='status'
                    rules={[
                      {
                        required: true,
                        message: 'Molim Vas da odaberete status!',
                      },
                    ]}
                  >
                    <Select getPopupContainer={(trigger) => trigger.parentNode} dropdownAlign={{ offset: [0, -170] }}>
                      <Select.Option value='ACTIVE'>AKTIVAN</Select.Option>
                      <Select.Option value='SUSPENDED'>SUSPENDOVAN</Select.Option>
                      <Select.Option value='DELETED'>OBRISAN</Select.Option>
                      <Select.Option value='WAITING_FOR_ACTIVATION'>ČEKA_NA_AKTIVACIJU</Select.Option>
                    </Select>
                  </Form.Item>
                )}

                <Form.Item {...tailLayout}>
                  <div>
                    <button className='b2b-primary' htmlType='submit' style={{ float: 'right' }}>
                      {data ? 'Snimi promene' : 'Dodaj korisnika'}
                    </button>
                    <button
                      className='b2b-secondary'
                      style={{ float: 'right', marginRight: '10px' }}
                      onClick={() => setShowModal(!showModal)}
                    >
                      Poništi
                    </button>
                  </div>
                </Form.Item>
              </Form>
            )}
            {result && (
              <Result
                title='Korisnik je kreiran i verifikacioni mejl je poslat'
                extra={
                  <Button
                    type='primary'
                    key='console'
                    onClick={() => {
                      setResult(false);
                      window.location.reload();
                    }}
                  >
                    Ok
                  </Button>
                }
              />
            )}
          </Card>

          {modal.visible && (
            <GalleryModal
              className='gallery-modal'
              visible={modal.visible}
              limit={modal.limit}
              formKey={modal.formKey}
              imageSavePath='public/images/users/'
              imageType='data'
              imageHeight={200}
              SERVER_URL={SERVER_URL}
              token={token}
              form={form}
              onCancel={() => setModal({ ...modal, visible: false })}
              onInsert={(values) => onInsert(values, modal.formKey)}
            />
          )}
          <Modal
            className='deleteModal'
            centered={true}
            visible={deletePop}
            onCancel={() => setDeletePop(!deletePop)}
            onOk={() => deleteUserHandler(user._id)}
            okText='Da'
            cancelText='Ne'
          >
            <p>Da li ste sigurni da želite da deaktivirate</p>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default UserForm;
