import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Input, notification } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const CompanyTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath }) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [branches, setBranches] = useState([]);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={'Pretraga za '+ t(`${dataIndex}`)}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 210, marginBottom: 8, display: 'block' }}
          />
          <button className='b2b-primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} 
          // size='small' 
          style={{ width: 90, marginRight: 8 }}>
            Traži
          </button>
          <button className='b2b-secondary'
          onClick={() => handleReset(clearFilters)} 
          // size='small' 
          style={{ width: 90 }}>
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const deleteBranch = async (id) => {
    try {
      const call = await axios.delete(`${SERVER_URL}/branches/${id}`, {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${user.data.token}`
        }
      });
      notification.success({
        message: call.data.message,
        placement: "bottomRight"
      });
      setBranches(branches.filter(branch => branch._id !== id));
    } catch (error) {
      console.log(error.message);
    }
  };

  const columns = columnKeys.map((item) => ({
    key: item,
    title: item,
    dataIndex: item,
    ...getColumnSearchProps(item),
  }));

  const setSelectedCompany = (id) => {
    localStorage.setItem("selectedCompany", id);
  };

  columns.push({
    title: 'action',
    render: (text, record) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <Link to='/admin/new-branch' onClick={() => setSelectedCompany(record._id)}>
            <PlusOutlined
              title={`Dodaj ogranak`}
              style={{ textDecoration: 'none', color: 'black' }} />
          </Link>
        </div>

        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <Link to={editPath + record._id}>
            <EditOutlined
              title={`Izmeni podatke`}
              style={{ textDecoration: 'none', color: 'black' }} />
          </Link>
        </div>

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={viewPath + record._id}>
              <EyeOutlined title={`View ${title.toLowerCase()}`} style={{ textDecoration: 'none', color: 'black' }} />
            </Link>
          </div>
        )}

        <div style={{ margin: '2px', padding: '4px' }}>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={`Ovo će obrisati kompaniju`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText='U redu'
            cancelText='Poništi'
            cancelButtonProps={{className: 'b2b-secondary'}}
            okButtonProps={{className: 'b2b-primary'}}
          >
            <DeleteOutlined title={`Brisanje kompanije`} />
          </Popconfirm>
        </div>
      </div>
    ),
  });

  const getBranches = async (id) => {
    try {
      const call = await axios.post(`${SERVER_URL}/company/branches`, { companies: id }, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.data.token}` }
      });
      setBranches(call.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const expandedRowRender = (record) => {
    const columns = [
      { title: 'Adresa', dataIndex: 'address', key: 'address' },
      { title: 'Grad', dataIndex: 'city', key: 'city' },
      { title: 'Ogranak', dataIndex: 'branchName', key: 'branchName' },
      { title: 'Kreirano', dataIndex: 'createdAt', key: 'createdAt' },
      { title: 'Ažurirano', dataIndex: 'updatedAt', key: 'updatedAt' },
      {
        title: "Opcije",
        render: (clicked) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
            <Link to={'/admin/edit-branch/' + clicked._id}>
              <EditOutlined title={`Izmeni ogranak`} style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} />
            </Link>
            <Popconfirm
              overlayClassName='desno-popconfirm'
              placement='left'
              title={`Da li želite da obrišete ogranak ${record.companyName}?`}
              onConfirm={() => deleteBranch(clicked._id)}
              okText='Da'
              cancelText='Ne'
            >
              <DeleteOutlined title={`Ukloni ogranak`} className='icon-unlock' style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} />
            </Popconfirm>
          </div >
        )
      }
    ];

    const data = [];
    for (let i = 0; i < branches.length; ++i) {
      data.push({
        key: i,
        _id: branches[i]._id,
        company: branches[i].company,
        address: branches[i].address,
        city: branches[i].city,
        branchName: branches[i].branchName,
        createdAt: new Date(branches[i].createdAt).toUTCString().substring(5, 22),
        updatedAt: new Date(branches[i].updatedAt).toUTCString().substring(5, 22),
      });
    }

    return (
      <Table
        columns={columns}
        dataSource={data.filter(r => r.company === record._id)}
        pagination={false}
      />
    );
  };
  for (let i = 0; i < columns.length; i++) {
    columns[i].title = t(`${columns[i].title}`);
  }

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        expandable={{ expandedRowRender }}
        onExpandedRowsChange={rows => getBranches(rows)}
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default CompanyTable;
