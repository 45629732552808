import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/PostTable';
import { SERVER_URL } from '../../config';
import { useTranslation } from 'react-i18next';
import { ExportOutlined } from '@ant-design/icons';
import ExportToExcel from '../../components/csv/ExportExcel';
import moment from 'moment';

const Orders = () => {
  const currentuser = useContext(UserContext);
  const [orders, fetchOrders] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    fetchOrders(`${SERVER_URL}/orders`, []);
  }, [fetchOrders]);

  const deleteOrderHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/orders/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Stavka je obrisana.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/orders');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim Vas da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const columnKeys = ['orderNumber', 'buyer', 'email', 'price', 'city', 'createdAt', 'updatedAt', 'status'];

  let tableData = [];
  if (orders.data && orders.data.items && orders.data.items.length > 0) {
    tableData = orders.data.items.map((item) => ({
      ...item,
      email: item.user ? item.user?.email : item.guestUserData.email,
      buyer: `${
        item.company?.branchName ? item.company?.branchName : item.branch?.companyName ? item.branch?.companyName : ''
      }`,
      city: item.company
        ? item.company.city
        : item.user.companyAdminInfo
        ? item.user?.companyAdminInfo.city
        : item.user?.companies[0]?.city || '',
      price: (+item.totalPriceWithTax).toFixed(2),
      createdAt: new Date(item.createdAt).toUTCString().substring(5, 22),
      updatedAt: new Date(item.updatedAt).toUTCString().substring(5, 22),
      status: t(item.status),
    }));
  }

  return (
    <div className='table'>
      <div className='table-first-row'>
        <ExportToExcel
          csvData={tableData != null && tableData.length >= 1 ? tableData : []}
          fileName={`Sva trebovanja ` + moment(new Date()).format('DD:MM:YYYY HH:MM')}
          propsToRemove={['_id', 'guestUserData', 'id', 'order', 'user', 'updatedAt', '__v', 'company', 'createdAt']}
        />
      </div>
      <div style={{ textAlign: 'center' }}>
        {orders.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!orders.isLoading && orders.data && orders.data.items && orders.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteOrderHandler}
            columnKeys={columnKeys}
            title='Order'
            editPath='/admin/edit-order/'
          />
        )}
        {!orders.isLoading && orders.data && orders.data.items && orders.data.items.length === 0 && (
          <div className='no-data-box'>
            <h2>NEMA PODATAKA</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Orders;
