import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../App';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { notification } from 'antd';
import { SERVER_URL } from '../../config';
import { CompanyBranch } from '../../components/forms';

export default function AddCompanyBranch(props) {
  const history = useHistory;
  const user = useContext(UserContext);
  const [company, setCompany] = useState([]);
  const selectedCompany = localStorage.getItem('selectedCompany');
  const [data, fetchData] = useAxios('', {}, user.data.token, 'get');
  const { id } = props.match.params;

  const getCompanies = useCallback(async () => {
    try {
      const call = await Axios.get(`${SERVER_URL}/companies/${selectedCompany}`, {
        widthCredetnails: false,
        headers: {
          Authorization: `Bearer ${user.data.token}`
        }
      });
      setCompany(call.data);
    } catch (error) {
      console.log(error.message);
    }
  }, [selectedCompany, user.data.token]);

  useEffect(() => {
    if (id) {
      fetchData(`${SERVER_URL}/branches/${id}`, []);
    }
    getCompanies();
  }, [fetchData, id, getCompanies]);

  const createBranch = async (values) => {
    try {
      const create = await Axios.post(`${SERVER_URL}/branches`, values, {
        widthCredetnails: false,
        headers: {
          Authorization: `Bearer ${user.data.token}`
        }
      });
      notification.success({
        message: create.data.message,
        placement: 'bottomRight'
      });
      history.push('/admin/companies');
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateBranch = async (values, id) => {
    try {
      const update = await Axios.put(`${SERVER_URL}/branches/${id}`, values, {
        widthCredetnails: false,
        headers: {
          Authorization: `Bearer ${user.data.token}`
        }
      });
      notification.success({
        message: update.data.message,
        placement: 'bottomRight'
      });
      history.push('/admin/companies');
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      {company &&
        <CompanyBranch
          company={company}
          branch={data.data}
          createBranch={createBranch}
          updateBranch={updateBranch}
        />
      }
    </div>
  );
}
