import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Select, Typography, Card } from 'antd';
import DataTable from '../tables/DataTable';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { useTranslation } from 'react-i18next';
const { Option } = Select;
const { Text } = Typography;
const formInit = {
  _id: null,
  orderNumber: null,
  user: {},
  totalPrice: null,
  status: '',
  order: [],
};

const OrderForm = ({ data, onSubmit, createInvoice }) => {
  const { t } = useTranslation();
  const [allProducts, setAllProducts] = useState([]);
  const [products, setProducts] = useState(data.order);
  const [total, setTotal] = useState(data.totalPrice ? data.totalPrice : 'Nema podataka');
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  const orderStatus = ['On hold', 'Delivered', 'Not delivered', 'Pending', 'Approved'];

  useEffect(() => {
    setTotal(
      products.reduce(
        (a, b) =>
          b.discountPercentage
            ? a + b.quantity * (b.price - (b.price * +b.discountPercentage) / 100)
            : a + b.price * b.quantity,
        0,
      ),
    );
  }, [products]);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const call = await axios.get(`${SERVER_URL}/data`);
      setAllProducts(call.data.items);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinish = async () => {
    onSubmit({
      ...data,
      order: products,
      totalPrice: total.toFixed(2),
      totalPriceWithTax: (+total + +total * 0.1).toFixed(2),
      status: form.getFieldValue('status'),
    });
  };

  const updateOrderAndcreateInvoice = async () => {
    createInvoice({
      ...data,
      order: products,
      totalPrice: total.toFixed(2),
      totalPriceWithTax: (+total + +total * 0.1).toFixed(2),
      status: form.getFieldValue('status') === 'On hold' ? 'Approved' : form.getFieldValue('status'),
    });
  };

  const discount = (percent, record) => {
    record.discountPercentage = percent;
    const newProducts = products.map((x) => products.find(({ id }) => id === record._id) || x);
    setProducts(newProducts);
  };

  const increment = (record) => {
    record.quantity++;
    const newProducts = products.map((x) => products.find(({ id }) => id === record._id) || x);
    setProducts(newProducts);
  };

  const decrement = (record) => {
    if (record.quantity !== 1) {
      record.quantity--;
      const newProducts = products.map((x) => products.find(({ id }) => id === record._id) || x);

      setProducts(newProducts);
    }
    return false;
  };

  const removeProduct = (record) => {
    const newProducts = products.filter((product) => product._id !== record);
    setProducts(newProducts);
  };

  const addProduct = (record) => {
    const findProd = allProducts.find((p) => p._id === record);
    const toAdd = {
      _id: findProd._id,
      category: findProd.category.name.sr,
      code: findProd.code,
      name: findProd.title.sr,
      quantity: 1,
      price: findProd.wholesalePrice,
    };
    const copy = [...products, toAdd];
    setProducts(copy);
  };

  const columnKeys = ['name', 'price'];

  let city = '';
  let address = '';
  let zip = '';
  let phone = '';
  if (data) {
    if (!data.company) {
      if (data.user.role[0] === 'companyAdmin') {
        city = data.user.companyAdminInfo.city;
        address = data.user.companyAdminInfo.address;
        zip = data.user.companyAdminInfo.zip;
        phone = data.user.companyAdminInfo.phone;
      } else if (data.user.role[0] === 'company') {
        city = data.user.companies[0].city;
        address = data.user.companies[0].address;
        zip = data.user.companies[0].zip;
        phone = data.user.companies[0].phone[0];
      }
    } else {
      city = data.company.city;
      address = data.company.address;
      zip = data.company.zip;
      phone = data.company.phone;
    }
  }

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          layout='vertical'
          form={form}
          // style={{pointerEvents:data.status==='Approved'?'none':'initial'}}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={8}>
              <div className='panel-body'>
                <Card title='Detalji trebovanja' bordered={false}>
                  <p>
                    <Text strong>Broj trebovanja: </Text>
                    {data.orderNumber ? data.orderNumber : 'Nema podataka'}
                  </p>
                  <p>
                    <Text strong>Kupac: </Text>
                    {console.log(data)}
                    {data.company?.branchName
                      ? data.company?.branchName
                      : data.branch?.companyName
                      ? data.branch?.companyName
                      : ''}
                  </p>
                  <p>
                    <Text strong>Grad: </Text>
                    {city}
                  </p>
                  <p>
                    <Text strong>Adresa: </Text>
                    {address}
                  </p>
                  <p>
                    <Text strong>Telefon: </Text>
                    {phone}
                  </p>
                  <p>
                    <Text strong>Poštanski kod: </Text>
                    {zip}
                  </p>
                  <p>
                    <Text strong>Ukupna cena: </Text>
                    {(+total).toFixed(2)}
                  </p>
                  <p>
                    <Text strong>Ukupna cena sa porezom: </Text>
                    {(+total + +total * 0.1).toFixed(2)}
                  </p>
                </Card>
                <br />
                <Form.Item label='Status trebovanja' name='status'>
                  <Select>
                    {orderStatus.map((item, index) => (
                      <Option key={index} value={item}>
                        {t(`${item}`)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <div className='panel-inside'>
                  <DataTable
                    data={products}
                    columnKeys={columnKeys}
                    title={'OrderTable'}
                    increment={increment}
                    decrement={decrement}
                    removeProduct={removeProduct}
                    discount={discount}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '1.2rem',
                  }}
                >
                  <Select
                    placeholder={t(`${'Search and add new products'}`)}
                    showSearch
                    style={{ width: '60%' }}
                    onSelect={(e) => addProduct(e)}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    disabled={false}
                  >
                    {allProducts?.map((product) => (
                      <Option value={product._id} key={product._id}>
                        {product.title.sr}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
              <button className='b2b-primary' htmlType='submit' style={{ margin: '0.5rem' }} onClick={() => onFinish()}>
                Ažuriraj
              </button>
              <button
                className='b2b-primary'
                onClick={() => updateOrderAndcreateInvoice()}
                style={{ margin: '0.5rem' }}
              >
                Potvrdi trebovanje
              </button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default OrderForm;
