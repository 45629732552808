import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import Data from '../pages/data/Data';
import EditData from '../pages/data/EditData';
import Categories from '../pages/data/Categories';
import Attributes from '../pages/data/Attributes';
import Invoices from '../pages/invoices/Invoice';
import Orders from '../pages/orders/Orders';
import EditOrder from '../pages/orders/EditOrders';
import Companies from '../pages/company/Company';
import EditCompany from '../pages/company/EditCompany';
import AddCompanyBranch from '../pages/company/AddCompanyBranch';

const routes = [
  {
    label: 'Korisnici',
    children: [
      {
        label: 'Svi korisnici',
        path: '/admin/users',
        component: Users,
        allowed: ['superAdmin', 'user'],
        showInMenu: true,
      },
      {
        label: 'Novi korisnik',
        path: '/admin/new-user',
        component: EditUser,
        allowed: ['superAdmin', 'user'],
        showInMenu: false,
      },
      {
        label: 'Izmena korisnika',
        path: '/admin/edit-user/:userId',
        component: EditUser,
        allowed: ['superAdmin', 'user'],
        showInMenu: false,
      },
      {
        label: 'Pregled korisnika',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['superAdmin', 'user'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Kompanije',
    children: [
      {
        label: 'Sve kompanije',
        path: '/admin/companies',
        component: Companies,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Dodaj kompaniju',
        path: '/admin/new-company',
        component: EditCompany,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni kompaniju',
        path: '/admin/edit-company/:id',
        component: EditCompany,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Dodaj ogranak',
        path: '/admin/new-branch',
        component: AddCompanyBranch,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Izmeni ogranak',
        path: '/admin/edit-branch/:id',
        component: AddCompanyBranch,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Galenski lekovi',
    children: [
      {
        label: 'Svi lekovi',
        path: '/admin/data',
        component: Data,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Novi proizvod',
        path: '/admin/new-data',
        component: EditData,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Edit Data',
        path: '/admin/edit-data/:dataId',
        component: EditData,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Kategorije',
        path: '/admin/data-categories',
        component: Categories,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Add/Edit Category',
        path: '/admin/data-categories/:categoryId',
        component: Categories,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Atributi',
        path: '/admin/data-attributes',
        component: Attributes,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Trebovanja',
    children: [
      {
        label: 'Sva trebovanja',
        path: '/admin/orders',
        component: Orders,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmena trebovanja',
        path: '/admin/edit-order/:orderId',
        component: EditOrder,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Porudžbenice',
    children: [
      {
        label: 'Sve porudžbenice',
        path: '/admin/invoices',
        component: Invoices,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
    ],
  },
];

export default routes;
